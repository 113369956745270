<template>
    <div>
     <mdb-card>
        <mdb-view class="blue pt-2 pb-2" cascade>
            <div class="d-flex justify-content-between align-items-center">
                <a href="" class="title-card-tec white-text ml-2">Catégories comptable</a>
                <mdb-btn
                    class=" text-primary float-right"
                    tag="a"
                    color="white"
                    icon="plus"
                    size="sm"
                    small
                >
                </mdb-btn>
            </div>
        </mdb-view>
        <mdb-card-body>
        
        </mdb-card-body>
     </mdb-card>
    </div>
</template>

<script>
import {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn
} from 'mdbvue'
export default {
    components:{
        mdbCard,
        mdbCardBody,
        mdbView,
        mdbBtn
    },
    data(){
        return {
            //
        }
    }
}
</script>